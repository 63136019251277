<template>
  <HEAD>
    <META http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <META name="description" content="云思shat软件注册版与试用版的区别" />
    <META name="keywords" content="云思shat软件注册版" />
    <META http-equiv="X-UA-Compatible" content="IE=7" />
    <TITLE>云思shat软件注册版与试用版的区别</TITLE>
    <LINK href="css/CSS.css" rel="stylesheet" type="text/css" />
  </HEAD>
  <BODY>
    <DIV id="bdy3">
      <DIV id="bdy4">
        <DIV id="head" class="w12">
          <DIV id="homepage"></DIV>

          <DIV style="padding-top: 40px"
            ><strong>全国统一服务热线：17347318127</strong></DIV
          >
        </DIV>

        <DIV id="navv">
          <DIV id="nav">
            <DIV class="nav1"><A href="/dist/index.html">首 页</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="index.html#/about" @click="go_link();">模拟考场</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/download">下载中心</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/price">软件价格</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/sell">购买方式</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav3"><A href="/dist/index.html#/about">联系我们</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
          </DIV>
        </DIV>

        <DIV id="tmain2">
          <DIV id="tm2_1"><img src="../images/32.png" /></DIV>
          <DIV id="cont_left" :style="height">
            <DIV id="tm2_2_1"><H2>客服中心</H2></DIV>
            <DIV class="cont_left_1_6">
              <UL id="m3_02_body">
                <LI id="m3_02_bodyimg2"></LI>

                <LI class="m3_02_bodyfont4">客服小云 QQ:896216279</LI
                ><LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=896216279&Site=软件客服&Menu=yes"
                    ><img
                      src="../ico/pa.gif"
                      alt="896216279"
                      hspace="2"
                      border="0"
                      align="absmiddle" /></a
                ></LI>
                <LI class="m3_02_bodyfont4">客服Cessary QQ:382599652</LI
                ><LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=382599652&Site=软件客服&Menu=yes"
                    ><img
                      src="../ico/pa.gif"
                      alt="382599652"
                      hspace="2"
                      border="0"
                      align="absmiddle" /></a
                ></LI>
                <LI class="m3_02_bodyfont4"> </LI>
                <LI id="m3_02_bodyimg3"></LI>
                <LI class="m3_02_bodyfont2">渠道小云 QQ:896216279</LI>
                <LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=896216279&Site=云思SHAT软件家族&Menu=yes"
                  >
                    <img
                      src="../ico/pa.gif"
                      hspace="2"
                      border="0"
                      align="middle"
                      width="74"
                      height="23" /></a
                ></LI>
                <LI class="m3_02_bodyfont4"> </LI>
                <LI id="m3_02_bodyimg4"></LI>
                <LI class="m3_02_bodyfont2">咨询热线：17347318127 </LI>
                <LI class="m3_02_bodyfont3">投诉：ctaco@foxmail.com</LI>
                <LI class="m3_02_bodyfont3">客服在线时间：周一至周日</LI>
                <DIV style="clear: both"></DIV>
              </UL>
            </DIV>
            <DIV id="tm2_2_3"><img src="../images/bg2.gif" /></DIV>
          </DIV>
          <DIV id="cont_right">
            <DIV id="tm2_3" style="margin-bottom: 15px; height: auto">
              <DIV id="tm2_3_1">
                <DIV id="tm2311" style="width: 237px; height: 47px"
                  ><H2>联系我们</H2></DIV
                >
              </DIV>

              <DIV class="tm2_3_2" id="show_21">
                <table border="0" width="97%" cellpadding="0" height="100%">
                  <tr>
                    <td>&nbsp;</td>
                    <td height="25" width="701">
                      <div align="center">
                        <table
                          style="border-collapse: collapse"
                          borderColor="#83bae9"
                          height="330"
                          cellPadding="0"
                          width="685"
                          border="0"
                          id="table1"
                        >
                          <tr>
                            <td align="left" width="531">
                              湖南友顺科贸有限公司成立于2013年07月15日，公司经营范围涵盖科技、贸易、信息电子技术服务,软件技术服务,信息系统集成服务等领域。公司技术力量雄厚，有各类高级工程师5人，中级工程师20人。拥有各类软件开发人员30人；拥有一级建造师5人，注册造价工程师1人，注册监理师3人；拥有信息系统项目管理师高级1人。经营状况良好。公司办公地址位于长沙市雨花区韶山中路448号融科三万英尺(右图)，交通便利，公交线路：7、107、602、102、137、502、801、703均可直达。距离地铁4号线砂子塘站2号出口100米。

                              <br />
                              <br />

                              <p>&nbsp;</p>
                              <p>
                                购买软件可联系客服开发票
                                (另加20元快递费,直接来我公司开发票的无额外费用)
                              </p>

                              <p>&nbsp;</p>
                              <p>
                                <font face="Verdana" color="#416a7c" size="3"
                                  >公司全国统一服务热线 17347318127</font
                                >
                              </p>
                            </td>

                            <td align="middle" width="242" height="320">
                              <img
                                border="0"
                                src="../images/company.png"
                                width="177"
                                height="310"
                                align="right"
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </table>
              </DIV>
            </DIV>
          </DIV>

          <DIV id="tm2_4" class="blu" style="overflow: hidden; height: 220px">
            <DIV id="m4_2_new">
              ICP许可证编号：<a href="https://beian.miit.gov.cn" target="_blank"
                >湘ICP备2021008170号-1</a
              >
              © 2021-2025 sellod.cn 版权所有
            </DIV>
          </DIV>
        </DIV>
      </DIV>
    </DIV>
  </BODY>
</template>

<script>
import "../css/CSS.css";
export default {
  data() {
    return {
      height: {
            height: window.innerHeight-200 + 'px'
        },
      height_left: {
          height: window.innerHeight-292 + 'px'
      },
    };
  },
  methods:{
    go_link(){
      var param="width="+window.screen.width * window.devicePixelRatio+",height="+window.screen.height * window.devicePixelRatio+",top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no";
      const neww = this.$router.resolve({name: 'Papers', params: {}});
      window.open(neww.href,"云思模拟考场",param);
    }
  }
};
</script>
<style scoped>
#m4_2_new a {
  color: #6b8191;
}
.STYLE36 {
  font-weight: bold;
  color: #0b66a8;
  font-size: 14px;
}
.STYLE17 {
  font-size: 12px;
  color: #333333;
}
.STYLE5 {
  color: #333333;
  font-size: 14px;
}
.STYLE21 {
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}
.STYLE27 {
  color: #333333;
  font-weight: bold;
}
.STYLE37 {
  font-size: 12px;
  color: #535353;
  font-weight: bold;
}
.STYLE18 {
  color: #333333;
  font-size: 12px;
  padding-left: 15px;
  width: auto;
}
.STYLE15 {
  font-size: 12px;
}
.STYLE42 {
  font-size: 12px;
  color: #ff603e;
  font-weight: bold;
}
.STYLE19 {
  font-size: 12px;
  color: #ff603e;
  font-weight: bold;
}
</style>
